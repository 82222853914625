import './App.css';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from 'react-router-dom';
import './assets/scss/general.scss';
import { ThemeProvider } from '@emotion/react';
import { Box, LinearProgress } from '@mui/material';
import theme from './Styles/CustomTheme';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import AppContext from './Contexts/AppContext';
import AlertPopupContainer, {
	HIDDEN_ALERT_OBJECT,
	ERORR_ALERT_OBJECT,
} from './Helpers/AlertPopup';
import DialogPopupContainer, {
	HIDDEN_DIALOG_OBJECT,
} from './Helpers/DialogPopup';
import LoginContext from './Contexts/LoginContext';
import { handlingAfterFetch } from './Helpers/Tools';
import {
	fetchVerifiedUser,
	getAllFields,
	getField,
	getPages,
	getServices,
	getSystemLanguages,
} from './services/api';
import OrderContext from './Contexts/OrderContext';
import theme_rtl from './Styles/CustomThemeRtl';
import { Suspense, lazy } from 'react';
import LinkPayment from './Screens/LinkPayment';
import NotFound from './Screens/NotFound';
import FloatingContactUs from './Components/FloatingContactUs';
import { hotjar } from 'react-hotjar';
import PopupTmp from './Components/PopupTmp';
import moment from 'moment';
import AccessibilityStatement from './Screens/AccessibilityStatement';
const LoginScreen = lazy(() => import('./Screens/LoginScreen'));
const ThankYou = lazy(() => import('./Screens/ThankYou'));
const ScreenHomeForUser = lazy(() => import('./Screens/ScreenHomeForUser'));
const ScreenHomeForAdmin = lazy(() => import('./Screens/ScreenHomeForAdmin'));
const ServiceChoosen = lazy(() => import('./Screens/ServiceChoosen'));
const Cart = lazy(() => import('./Components/Cart'));
const Checkout = lazy(() => import('./Screens/Checkout'));

const cacheRtl = createCache({
	key: 'muirtl',
	stylisPlugins: [prefixer, rtlPlugin],
});

function App() {
	const [loggedInUser, setLoggedInUser] = useState(null); //null
	const [alertObject, setAlertObject] = useState(HIDDEN_ALERT_OBJECT);
	const [dialogObject, setDialogObject] = useState(HIDDEN_DIALOG_OBJECT);
	const [files, setFiles] = useState([]);
	const [allFields, setAllFields] = useState([]);
	const [fullName, setFullName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [permissions, setPermissions] = useState('');
	const [systemLanguages, setSystemLanguages] = useState([]);
	const [currentLanguage, setCurrentLanguage] = useState({});
	const [pages, setPages] = useState([]);
	const [cart, setCart] = useState({});
	const [services, setServices] = useState([]);
	const [currentService, setCurrentService] = useState({});
	// const [price, setPrice] = useState(0);
	const [isTranslation, setIsTranslation] = useState(
		currentService?.id === 2 || currentService?.id === 4 ? true : false,
	);
	const [isRtl, setIsRtl] = useState(
		localStorage.getItem('lang') === 'Eng' ? false : true,
	);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [pathname, setPathname] = useState(window.location.href);
	//for admin panel
	const [currentScreen, setCurrentScreen] = useState('');
	const [isFilesUploaded, setIsFilesUploaded] = useState(false);
	const [isShowFailurePopup, setIsShowFailurePopup] = useState(false);

	const cleanUserData = () => {
		setEmail('');
		setPassword('');
		setPermissions('');
		setFullName('');
	};

	const getDefaultLang = async () => {
		let defaultLanguage;
		let pastDefaultLanguage = localStorage.getItem('lang');
		await handlingAfterFetch(
			getSystemLanguages(),
			(result) => {
				setSystemLanguages(result.data);
				defaultLanguage = result.data.find((el) =>
					pastDefaultLanguage === 'Eng'
						? el.defaultLang === false
						: el.defaultLang === true,
				);
				if (defaultLanguage) {
					setCurrentLanguage(defaultLanguage);
					getAllPages(defaultLanguage.id);
					getSiteServices(defaultLanguage.id);
				}
			},
		);
	};

	const getSiteServices = async (lang_id) => {
		await handlingAfterFetch(
			getServices(lang_id),
			(result) => {
				setServices(result.data);
				setCurrentService(result.data[0]);
			},
		);
	};

	const getAllPages = async (lang_id) => {
		await handlingAfterFetch(getPages(lang_id), (result) => {
			setPages(result.data);
		});
	};

	const verifyUserSessionIsActive = async () => {
		try {
			const verifiedUser = await fetchVerifiedUser();
			if (verifiedUser.success && verifiedUser.data.email) {
				//verifiedUser.data.isAdmin = (verifiedUser.data.permissions.toLowerCase() === PERMISSIONS_ADMIN);
				setLoggedInUser({ ...verifiedUser.data });
				return;
			} else if (verifiedUser.status !== 200) {
				setIsShowFailurePopup(true);
				// setAlertObject({
				// 	...ERORR_ALERT_OBJECT,
				// 	message: verifiedUser.message,
				// });
			}
			setLoggedInUser({});
		} catch (error) {
			setAlertObject({
				...ERORR_ALERT_OBJECT,
				message: `Unexpected error in verifying user session: ${error}`,
			});
		}
	};

	const ProtectedRoute = ({ children }) => {
		if (!loggedInUser) {
			// user is not authenticated
			return <Navigate to='/auth' />;
		}
		return children;
	};

	const getPageField = async (fieldname, pageId) => {
		let res = {};
		await handlingAfterFetch(
			getField(fieldname, pageId),
			(result) => {
				res = result.data;
				return;
			},

		);
		return res;
	};

	const handleGetAllFields = async () => {

		await handlingAfterFetch(
			getAllFields(), 
			(result) => {
				if (result.success && result.data) {
					setAllFields(result.data);
				}
			},
			false
		);
	};

	const handleUpdateDimensions = () => {
		const width = window.innerWidth;
		setWindowWidth(width);
	};

	useEffect(() => {
		if(!isShowFailurePopup){
			verifyUserSessionIsActive();
			handleGetAllFields();
			getDefaultLang();
			handleUpdateDimensions();
		}

		window.addEventListener('resize', handleUpdateDimensions);
		return () =>
			window.removeEventListener('resize', handleUpdateDimensions);
	}, []);

	useEffect(() => {
		hotjar.initialize(3661571, 6);
		hotjar.identify('USER_ID', { userProperty: 'value' });

		// Add an event
		hotjar.event('button-click');

		// Check if Hotjar has been initialized before calling its methods
		if (hotjar.initialized()) {
			// console.log('initialized');
			hotjar.identify('USER_ID', { userProperty: 'value' });
		}
	}, []);

	useEffect(() => {
		if(!isShowFailurePopup){
			getAllPages(currentLanguage.id);
			getSiteServices(currentLanguage.id);
		}
	}, [currentLanguage]);

	useEffect(() => {
		if (isRtl) {
			document.dir = 'rtl';
			document.body.style.direction = 'rtl';
			document.body.classList.remove('ltr');
			document.body.classList.add('rtl');
			return;
		}
		document.dir = 'ltr';
		document.body.style.direction = 'ltr';
		document.body.classList.remove('rtl');
		document.body.classList.add('ltr');
	}, [isRtl]);

	const appContextData = {
		alertObject,
		setAlertObject,
		dialogObject,
		setDialogObject,
		systemLanguages,
		currentLanguage,
		setCurrentLanguage,
		getPageField,
		isRtl,
		setIsRtl,
		currentScreen,
		setCurrentScreen,
		allFields,
		windowWidth,
		pages,
	};

	const orderContextData = {
		files,
		setFiles,
		isTranslation,
		setIsTranslation,
		cart,
		setCart,
		services,
		setServices,
		currentService,
		setCurrentService,
		isFilesUploaded,
		setIsFilesUploaded,
		// price,
		// setPrice,
	};

	const loginContextData = {
		loggedInUser,
		setLoggedInUser,
		fullName,
		email,
		password,
		permissions,
		cleanUserData,
	};

	const renderDom = () => {
		return (
			<ThemeProvider theme={isRtl ? theme_rtl : theme}>
				<AppContext.Provider value={appContextData}>
					<LoginContext.Provider value={loginContextData}>
						<OrderContext.Provider value={orderContextData}>
							<AlertPopupContainer />
							<DialogPopupContainer />
							{(!pathname.includes('/admin_panel') ||
								!pathname.includes('/auth')) && (
								<FloatingContactUs />
							)}
							<Box>
								<Router>
									<Routes>
										<Route
											exact
											path='/'
											element={
												<Suspense
													fallback={
														<LinearProgress />
													}
												>
													<ScreenHomeForUser
														page_id={isRtl ? 1 : 2}
													/>
												</Suspense>
											}
										/>
										<Route
											exact
											path='/checkout'
											element={
												<Suspense
													fallback={
														<LinearProgress />
													}
												>
													<Checkout
														page_id={isRtl ? 6 : 3}
													/>
												</Suspense>
											}
										/>
										<Route
											exact
											path='/checkout/link_payment/:user_id/:order_id'
											element={
												<Suspense
													fallback={
														<LinearProgress />
													}
												>
													<LinkPayment
													// page_id={isRtl ? 6 : 3}
													/>
												</Suspense>
											}
										/>
										<Route
											exact
											path='/cart'
											element={
												<Suspense
													fallback={
														<LinearProgress />
													}
												>
													<Cart
														page_id={isRtl ? 4 : 5}
													/>
												</Suspense>
											}
										/>
										<Route
											exact
											path='/service_chosen'
											element={
												<Suspense
													fallback={
														<LinearProgress />
													}
												>
													<ServiceChoosen
														page_id={isRtl ? 8 : 7}
													/>
												</Suspense>
											}
										/>
										<Route
											exact
											path='/thank_you'
											element={
												<Suspense
													fallback={
														<LinearProgress />
													}
												>
													<ThankYou />
												</Suspense>
											}
										/>
										<Route
											exact
											path='/accessibility_statement'
											element={
												<Suspense
													fallback={
														<LinearProgress />
													}
												>
													<AccessibilityStatement />
												</Suspense>
											}
										/>
										<Route
											exact
											path='/auth/*'
											element={
												<Suspense
													fallback={
														<LinearProgress />
													}
												>
													<LoginScreen />
												</Suspense>
											}
										/>
										<Route
											path='/admin_panel'
											element={
												<ProtectedRoute>
													<Suspense
														fallback={
															<LinearProgress />
														}
													>
														<ScreenHomeForAdmin />
													</Suspense>
												</ProtectedRoute>
											}
										/>
										{/* for 404 */}
										<Route
											path='*'
											element={
												<Suspense
													fallback={
														<LinearProgress />
													}
												>
													<NotFound />
												</Suspense>
											}
										/>
									</Routes>
								</Router>
								<PopupTmp showPopup={isShowFailurePopup}/>
							</Box>
						</OrderContext.Provider>
					</LoginContext.Provider>
				</AppContext.Provider>
			</ThemeProvider>
		);
	};

	return (
		<>
			{isRtl && (
				<CacheProvider value={cacheRtl}>{renderDom()}</CacheProvider>
			)}
			{!isRtl && renderDom()}
		</>
	);
}

export default App;
