import Axios from 'axios';
//const NODE_ENV = 'development';
const NODE_ENV = 'production';

// export const SERVER_ENDPOINT =
// 	NODE_ENV === 'development'
// 		? 'http://localhost:3013'
// 		: 'https://pq.hevertranslations.co.il:3013';

export const SERVER_ENDPOINT =
	NODE_ENV === 'development'
		? 'http://localhost:3013'
		: 'https://app.hevertranslations.co.il';

export const CLIENT_DOMAIN =
	NODE_ENV === 'development'
		? 'http://localhost:3000'
		: 'https://pq.hevertranslations.co.il';

const parseRequestResponse = async (request, description) => {
	try {
		const response = await request;
		const common_data = {
			status: response.status,
			statusText: response.statusText,
		};
		if (response.status === 200) {
			const result = response.data;

			if (result.success) {
				return { ...common_data, ...result };
			} else {
				return {
					...common_data,
					...result,
					message: `${description} error: ${result.message}`,
				};
			}
		} else if (response.status === 401) {
			// bad login data
			return {
				...common_data,
				message: `Wrong user credentials or user session is expired! Please, re-login...`,
			};
		} else {
			return {
				...common_data,
				message: `Server has responded with eror status ${response.status} on getting ${description}: ${response.statusText}`,
			};
		}
	} catch (error) {
		return {
			status: 0,
			statusText: '',
			message: `Unexpected error while processing ${description}: ${error}`,
		};
	}
};

export const fetchGetExternalUrl = async (url, description) => {
	const result = await Axios.get(url);
	return result;
};

export const fetchGet = async (url, description) => {
	const result = await parseRequestResponse(
		Axios.get(url, {
			withCredentials: true,
			mode: 'cors',
			credentials: 'include',
			headers: {
				// 'Access-Control-Allow-Origin':
				// 	'https://pq.hevertranslations.co.il',
			},
		}),
		description,
	);
	return result;
};

export const fetchPost = async (
	url,
	data,
	content_type = 'application/json',
	description = '',
) => {
	return await parseRequestResponse(
		Axios.post(url, data, {
			withCredentials: true,
			mode: 'cors',
			credentials: 'include',
			headers: {
				'Access-Control-Allow-Origin':
					'https://pq.hevertranslations.co.il',
				'Content-Type': content_type,
				// 	'Access-Control-Allow-Origin':
				// 		'https://pq.hevertranslations.co.il',
			},
		}),
		description,
	);
};

export const fetchPostFiles = async (
	url,
	data,
	options,
	description = 'Add New File',
) => {
	return await parseRequestResponse(
		Axios.post(url, data, options),
		description,
	);
};

export const fetchPut = async (
	url,
	data,
	content_type = 'application/json',
	description = '',
) => {
	return await parseRequestResponse(
		Axios.put(url, data, {
			withCredentials: true,
			mode: 'cors',
			credentials: 'include',
			headers: {
				'Access-Control-Allow-Origin':
					'https://pq.hevertranslations.co.il',
				'Content-Type': content_type,
			},
		}),
		description,
	);
};

export const fetchDelete = async (
	url,
	data,
	content_type = 'application/json',
	description = '',
) => {
	return await parseRequestResponse(
		Axios.delete(url, {
			withCredentials: true,
			mode: 'cors',
			credentials: 'include',
			headers: {
				'Access-Control-Allow-Origin':
					'https://pq.hevertranslations.co.il',
				'Content-Type': content_type,
			},
			data,
		}),
		description,
	);
};
