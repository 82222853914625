import { Box, IconButton, Modal, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import AppContext from '../Contexts/AppContext';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Link } from 'react-router-dom';
import theme from '../Styles/CustomTheme';

const wrapperStyle = (isCentered) => {
	return {
        zIndex: isCentered ? 1300 : 0,
        position: isCentered ? 'fixed' : 'relative',
        height: isCentered ? '100vh' : 'max-content',
        background: isCentered ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
        top: isCentered ? 0 : 'auto',
        right: isCentered ? 0 : 'auto',
        left: 0,
        bottom: 0
    }
};

const modalStyle = (isCentered, windowWidth) => {
	return {
        position: isCentered ? 'absolute' : 'relative',
        top: isCentered ? '50%' : 'auto',
        bottom: isCentered ? 'auto' : '0',
        left: isCentered ? '50%' : '0',
        transform: isCentered ? 'translate(-50%, -50%)' : 'none',
        width: isCentered ? 'min(90%, 800px)' : '100%',
        borderRadius: isCentered ? 'max(16px, 1.5vw)' : 'max(16px, 1.5vw) max(16px, 1.5vw) 0 0',
        bgcolor: '#f5f5f5',
        boxShadow: 24,
        p: isCentered ? 5 : 2,
        maxHeight: '90vh',
        overflowY: 'auto',
        outline: 'none',
        zIndex: 1,
        '.close-btn':{
            top: '3%',
            left: '2%',
            opacity: isCentered ? 1 : 0,
            visibility: isCentered ? 'visible' : 'hidden'
        },
        'a':{
            '&:hover': {
                color: theme.palette.primary.dark
            }
        }
    }
};

function PopupTmp({showPopup}) {
    // const [showPopup, setShowPopup] = useState(isShowPopup || false);
    const [isCentered, setIsCentered] = useState(true);
	const {isRtl, windowWidth} = useContext(AppContext);

    // const handleCloseModal = () => {
    //     setIsCentered(false);
	// };

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //       setShowPopup(true);
    //     }, 1000);
    //     return () => clearTimeout(timer);
    //   }, []);

    // useEffect(() => {
    //     const lastVisit = localStorage.getItem('lastVisit');
    //     const currentTime = new Date().getTime();
    
    //     if (!lastVisit || currentTime - lastVisit > 24 * 60 * 60 * 1000) {
    //       setIsCentered(true);
    //       localStorage.setItem('lastVisit', currentTime);
    //     }
    // }, []);

    return (
        showPopup && (
            <Box
                sx={() => wrapperStyle(isCentered)}
                className='modal'
			>
				<Box sx={()=>modalStyle(isCentered, windowWidth)}>
                    {isRtl ? 
                        <>
                            {/* <IconButton size="large" color='primary' aria-label="close" className='close-btn absolute' onClick={handleCloseModal}>
                                <CloseRoundedIcon fontSize="inherit"/>
                            </IconButton> */}
                            <Typography variant={isCentered ? 'h2' : 'h3'} component='h3' textAlign={'center'}>{`אופס, משהו השתבש`}</Typography>
                            <Typography variant='p' component='p' mt={isCentered ? 3 : 1}>
                                {` אנחנו עובדים על התקלה ואוטוטו חוזרים לאוויר`}
                                {`בינתיים, אפשר למצוא אותנו `}
                                <a href="https://www.hever.co.il/contact-employer/" target="_blank" rel="noopener noreferrer">{`באתר`}</a>, 
                                <a href="https://wa.me/972548890777" target="_blank">{`בווטאסאפ`}</a>  
                                    {` או `}
                                <a href="https://www.hever.co.il/" target="_blank" rel="noopener noreferrer">{`בצ'אט.`}</a> 
                            </Typography>
                            <Typography variant='p' component='p' mt={isCentered ? 3 : 1}>
                                {`שלכם, חבר תרגומים`}
                            </Typography>
                        </>
                    :
                        <>
                            <Typography variant={isCentered ? 'h2' : 'h3'} component='h3' textAlign={'center'}>{`Oops, something went wrong`}</Typography>
                            <Typography variant='p' component='p' mt={isCentered ? 3 : 1}>
                                {`We are working on the problem and soon will be back.`}
                                {`You can get in touch through `}
                                <a href="https://www.hever.co.il/contact-employer/" target="_blank" rel="noopener noreferrer">{`our website`}</a>, 
                                <a href="https://wa.me/972548890777">{` on WhatsApp`}</a>  
                                    {` or `}
                                <a href="https://www.hever.co.il/" target="_blank" rel="noopener noreferrer">{`in chat`}</a> 
                            </Typography>
                            <Typography variant='p' component='p' mt={isCentered ? 3 : 1}>
                                {`Yours, Hever Translations`}
                            </Typography>
                        </>
                    }
				</Box>
			</Box>
        )
    );
}

export default PopupTmp