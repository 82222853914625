import { Box } from '@mui/material';
import { useContext } from 'react';
import AppContext from '../Contexts/AppContext';
import theme from '../Styles/CustomTheme';
import MessageIcon from '@mui/icons-material/Message';

const floatingStyles = (windowWidth) => {
	return {
		position: 'fixed',
		bottom: '11vh',
		left: windowWidth > 768 ? '2vmin' : 'auto', 
		right: windowWidth > 768 ? 'auto' : 0,
		padding: '1.5vmin',
		paddingInlineStart: windowWidth > 768 ? '1.5vmin' : '2.5vmin',
		borderRadius: windowWidth > 768 ? '100vw' : '100vw 0 0 100vw',
		background: theme.palette.grey[100],
		boxShadow: 2,
		color: theme.palette.secondary.dark,
		cursor: 'pointer',
		zIndex: 99,
		'&:hover': {
			background: theme.palette.grey[200],
		},
	}
};

const FloatingContactUs = () => {
	const { isRtl, windowWidth } = useContext(AppContext);
	return (
		<Box sx={() => floatingStyles(windowWidth)}>
			<a
				href={'https://www.hevertranslations.co.il/for-quote/'}
				target='_blank'
				rel={'noreferrer'}
				className='flex align-center'
				style={{ gap: '8px'}}
			>
				<MessageIcon />
				{windowWidth > 768 && (isRtl ? 'לא מצאת את מה שחיפשת?' : 'Contact Us')}
			</a>
		</Box>
	);
};

export default FloatingContactUs;
