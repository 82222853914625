import React from 'react'
import { styled } from '@mui/system';
import bckg from '../assets/imgs/bckg_login.png'
import theme from './CustomTheme';

export const CustomContainer = styled('div')({
    backgroundImage: `url(${bckg})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    minHeight: '100vh',
    textAlign: 'center',
});

export const MainContainer = {
    boxShadow: '0px 0px 70px rgba(51, 150, 209, 0.15)',
    borderRadius: '4px',
    minHeight: '80vh',
    width: 'max(300px, 55vw)',
    background: theme.palette.grey['50'],
    [theme.breakpoints.down("xl")]: {
        width: 'max(250px, 90vw)',
    },
    
};

export const AdminMainContainer = {
    boxShadow: '0px 0px 70px rgba(51, 150, 209, 0.15)',
    borderRadius: '4px',
    height: '60vh',
    paddingBottom: '50px',
    width: 'max(300px, 70vw)',
    background: theme.palette.grey['50'],
    overflowY: 'auto',
    
};

export const margin_styles = (param) => ({
    margin: param
});

export const padding_styles = (param) => ({
    padding: param
});

export const main_btn_block_style = (param) => ({
    width: param ? param : '100%',
    borderRadius: '4px',
    boxShadow: '0px 0px 30px rgba(51, 150, 209, 0.15)',
    //columnGap: '8px'
})

export const abs_position = (param) => ({
    position: 'absolute',
    top: param.top,
    right: param.right,
    left: param.left ? param.left : null,
    transform: param.transform ? param.transform : null
});

export const table_styles = {
    overflow: 'hidden',
    width: "100%",
    boxShadow: '0px 4px 8px -1px rgba(0 ,0 ,0 , 0.1), 0px 2px 8px 0px rgba(0 ,0 , 0 , 0.1), 0px 1px 8px 0px rgba(0 ,0, 0 , 0.1)',
    borderRadius: 'max(25px, 3vw)',
    marginBlock: '2vw',
    background: theme.palette.grey[50],
    height: '80vh',
    paddingBottom: '50px',
}