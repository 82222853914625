import React, { useContext, useEffect, useState } from 'react';
import {
	Box,
	AppBar,
	Grid,
	Toolbar,
	Typography,
	FormGroup,
	FormControlLabel,
	Switch,
} from '@mui/material';
import logo from '../assets/imgs/logo.png';
import logo_hever_eng from '../assets/imgs/Translations_Logo_eng.png';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import AppContext from '../Contexts/AppContext';
import theme from '../Styles/CustomTheme';
import OrderContext from '../Contexts/OrderContext';
import { EditCartService } from '../services/api';
import { handlingAfterFetch } from '../Helpers/Tools';

function Header({ isTransparent }) {
	const {
		setAlertObject,
		currentLanguage,
		setCurrentLanguage,
		systemLanguages,
		isRtl,
		setIsRtl,
		windowWidth,
	} = useContext(AppContext);

	const {
		cart,
		setCart,
	} = useContext(OrderContext);

	const header_styles = {
		maxWidth: '100%',
		padding:
			windowWidth > 1024
				? 'max(16px, 1.5vw) max(30px, 8.9vw) !important'
				: 'max(16px, 1.5vw) 5px !important',
		boxShadow: isTransparent ? 'none' : '0px 0px 50px rgba(0, 0, 0, 0.16)',
		maxHeight: '140px',
		background: isTransparent ? 'transparent' : '#fff',
	};

	const MaterialUISwitch = styled(Switch)(({ theme }) => ({
		width: 52,
		height: 28,
		padding: 7,
		'& .MuiSwitch-switchBase': {
			margin: 1,
			padding: 0,
			transform: 'translateX(6px)',
			'&.Mui-checked': {
				transform: 'translateX(22px)',
				'& .MuiSwitch-thumb:before': {
					backgroundColor: theme.palette.primary.main,
					// backgroundImage: `
					// ${isRtl ? "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAmBJREFUSEu1VjtsE0EQnTmHwjokTGOXOC7BQKJUNGCwQKHCSEggUXABEokmgtCAREQgRdIAUhqk8ElSIAECYSoikMlBH9mBQGm7tRuM5E8T3zC7xCcbLnt7oLiw1t4382be7swsguKTGn4YBwqdAsAMEEUQcUDAiagAiDVeZQHbb+3lK+Wt3KDXxqbj2whoqQLo7BHQIhPd8SL6iyB14nGGI1tAhIiOc5eEgDPCEfv95Wy3XQ9B6vi8hQYuBHH8J5YcGrE/jC12/ncJXr7OW0TwX867yE6fPTMoM5EErVYr3m6383yIgWTZKlO+BLVQKDQYDofLkqDRaIiULvhJU6nWJSQW3ekHFftLpmlaKKJ3HKekYzE9+1mmfOvGYR04GIbRj81m8yqn9MDP4st6BR49WZWw0UtDcCAZ8zPhUsFrWK/XbV4c8UI/e/4V1r9X5Vax9ANMc4cL68iU3BuF8+f2e5Jx4J+Q9RfyxL0QQvObkzlIH02AWE+MH5IwIVUivhtydhFm7qZVZ1IQBKTKdf7pKuQ+FmHu/knXkSAbn3gH6WMJGLs4pJRKj2ClBHP3hnsJri9zZv1aBGUOYc8/SbTCEk0rJVrzP+RvFcldqTZ6YohFTfk7uS+mPuTA15QLYZR1176mQQtNRD0ZpNCEwba2CkGw2ewKXHC7fMtTA8AF9pOb3YDb7ITNi1d5HjTwRsNeB9LbrjsW2zpwXBIemQgOz9iAcrEsBIalHJkuiXhNOH1TPJd9Z4Sw4Um4BMbGlNbQ7xb49+uiL4NE4nwinNVBuU+0xt81QuRny0ZW9Wz5Bb3HISdV4oXJAAAAAElFTkSuQmCC')"
					//   : "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAA0xJREFUSEtjZMADth68q/voxdeCSHc5pU/VEzS+TV0uAVLOlR35gq+14MbynY/uyUlwT/C2V76MyxhGbBLLt101PHP17aqr9z6pCPGxMUyrNGP40jEbiOeAlfNUpABxKkNW+ymGd59+MegqC9420hIIj/TSPo9uHoYFpy88jO1YeH3hp6+/wXKighxAC0xxWHCa4fX7H2Az+bhZ/1fEa8abGsgvRrYExYLHdf0dvEWJ5ccvvWGYsOwGw79//4mygImJkaHIV4ZBuaKGgbsuq1Qy1KsHZgncgr0n7pUopOZ1s+iqMfBPq2MAWdK/9DqDiAB+H7z9+BNheGYEA2eUD8Pxy2/SPW1VZoEsAVuwcc8Nvbkb711IdRZn1KqtZ4BZsu/0S4a1ex4yTMURB9nAOIizE4O4HGr4gTMvGeZtvPs/2V/JwN9F4xLYguLuw3cu3f6gDGLneUkxIFty4eZ7BgN1QaxxcOnMYwapwkoUw6euugUOHT1Vgbu9pbYqjCDXT1l1+yJyxKBbApLDloo+RJcysHvbg4MF5HKY4TCzcsJU9Rmnr7ww9+b9T0noySvRQYyBv7kXbgA2C/5cvgUOzgfPvjIs2HQXI8WrK/LNY3z//v0BRkZGe1wZBSaOzQJCev7//3+Q8VFBywcmDnZ+Qop/HTnHAMIgwGZjBMaEwP8v314w3mBQ/09IISXytLeAlkH09/PXl7SPZFAyvYEtmfopMyhIcTN8X7YFnM5xJVOQ+MfaYob5B14xoEemBiiZYsto2WFqDA4m4gwfs5oY/n/8zCCwtBurBaDIBzng2/QVDNeaGxkmbXuGkh7AGQ29qEA2HJSRnva3M+iZyGK1AFaMYLMEXlTAC7tN9y4mAYMF5nKQ4VeaGhlWnXiLt7ALdpFncDIVR/HJ7H0vGZL9lPThhR3Iku2H76RZ6orMBAULyPA7HS0MvZseEyyu33z4wVAYrclgqScCt+TWtAmoxTUs4EAVzq/1e8tBhvdtfkJShVMQpQGxZPcx7BUOzBKaVpkwS0CV/rlrH1ZevvtelVCl/+HzbwZtJQHiK33kdAZqtjx8/rkw0l1e6VPNRI3vU5eLg+Q5syNf8rXkA5stD++pKQj2uFjIX8NVXgEAIasn+Hx65zcAAAAASUVORK5CYII=')"
					// }
					// `,
				},
				'& + .MuiSwitch-track': {
					opacity: 1,
					backgroundColor: '#aab4be',
				},
			},
		},
		'& .MuiSwitch-thumb': {
			backgroundColor: 'transparent',
			width: 24,
			height: 24,
			'&:before': {
				content: "''",
				position: 'absolute',
				width: '100%',
				height: '100%',
				left: 0,
				top: 0,
				boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.8)',
				borderRadius: '50%',
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'center',
				backgroundColor: theme.palette.primary.main,
				//   backgroundImage: `url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAA0xJREFUSEtjZMADth68q/voxdeCSHc5pU/VEzS+TV0uAVLOlR35gq+14MbynY/uyUlwT/C2V76MyxhGbBLLt101PHP17aqr9z6pCPGxMUyrNGP40jEbiOeAlfNUpABxKkNW+ymGd59+MegqC9420hIIj/TSPo9uHoYFpy88jO1YeH3hp6+/wXKighxAC0xxWHCa4fX7H2Az+bhZ/1fEa8abGsgvRrYExYLHdf0dvEWJ5ccvvWGYsOwGw79//4mygImJkaHIV4ZBuaKGgbsuq1Qy1KsHZgncgr0n7pUopOZ1s+iqMfBPq2MAWdK/9DqDiAB+H7z9+BNheGYEA2eUD8Pxy2/SPW1VZoEsAVuwcc8Nvbkb711IdRZn1KqtZ4BZsu/0S4a1ex4yTMURB9nAOIizE4O4HGr4gTMvGeZtvPs/2V/JwN9F4xLYguLuw3cu3f6gDGLneUkxIFty4eZ7BgN1QaxxcOnMYwapwkoUw6euugUOHT1Vgbu9pbYqjCDXT1l1+yJyxKBbApLDloo+RJcysHvbg4MF5HKY4TCzcsJU9Rmnr7ww9+b9T0noySvRQYyBv7kXbgA2C/5cvgUOzgfPvjIs2HQXI8WrK/LNY3z//v0BRkZGe1wZBSaOzQJCev7//3+Q8VFBywcmDnZ+Qop/HTnHAMIgwGZjBMaEwP8v314w3mBQ/09IISXytLeAlkH09/PXl7SPZFAyvYEtmfopMyhIcTN8X7YFnM5xJVOQ+MfaYob5B14xoEemBiiZYsto2WFqDA4m4gwfs5oY/n/8zCCwtBurBaDIBzng2/QVDNeaGxkmbXuGkh7AGQ29qEA2HJSRnva3M+iZyGK1AFaMYLMEXlTAC7tN9y4mAYMF5nKQ4VeaGhlWnXiLt7ALdpFncDIVR/HJ7H0vGZL9lPThhR3Iku2H76RZ6orMBAULyPA7HS0MvZseEyyu33z4wVAYrclgqScCt+TWtAmoxTUs4EAVzq/1e8tBhvdtfkJShVMQpQGxZPcx7BUOzBKaVpkwS0CV/rlrH1ZevvtelVCl/+HzbwZtJQHiK33kdAZqtjx8/rkw0l1e6VPNRI3vU5eLg+Q5syNf8rXkA5stD++pKQj2uFjIX8NVXgEAIasn+Hx65zcAAAAASUVORK5CYII=')`,
			},
		},
		'& .MuiSwitch-track': {
			opacity: 1,
			backgroundColor:
				theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
			borderRadius: 20 / 2,
		},
	}));

	const handleChangeLang = async(e) => {
		const newCurrLang = systemLanguages.find(
			(lang) => +lang.id !== +currentLanguage.id,
		);
		if (!newCurrLang) return; 
		localStorage.setItem('lang', newCurrLang.code);
		setCurrentLanguage(newCurrLang);
		setIsRtl(!isRtl);
		
		if(!cart.id) return;
		await handlingAfterFetch(
			EditCartService({cartId: cart.id, userLangId: newCurrLang.id}),
			(res) => {
				// console.log('result', res);
				if(res.success && res.data) setCart(res.data);
			},
			setAlertObject
		);
	};

	return (
		<Box sx={{ direction: `${isRtl ? 'ltr' : 'rtl'}` }}>
			<AppBar
				position='fixed'
				className='header-wrapper'
				color='grey'
				sx={header_styles}
			>
				<Toolbar>
					<Grid
						container
						className={`justify-between align-center nowrap`}
					>
						{/* {windowWidth < 1024 && <Link to="/" className="logo"><img src={logo} width={162} alt="Logo"/></Link>} */}
						{!isTransparent && (
							<Link to='/' className='logo'>
								<img
									src={isRtl ? logo : logo_hever_eng}
									width={`${
										windowWidth > 575 ? '162px' : '100px'
									}`}
									alt='Logo'
								/>
							</Link>
						)}
						<FormGroup
							sx={{
								width: 'max(170px, 15%)',
								flexDirection: 'row',
								alignItems: 'center',
							}}
						>
							<Typography>
								{
									systemLanguages?.find(
										(el) => el.defaultLang === false,
									)?.languageName
								}
							</Typography>
							<FormControlLabel
								sx={{ marginInline: '0' }}
								control={
									<MaterialUISwitch
										sx={{ m: 1 }}
										onChange={(e) => handleChangeLang(e)}
										defaultChecked={true}
									/>
								}
							/>
							<Typography>
								{
									systemLanguages?.find(
										(el) => el.defaultLang === true,
									)?.languageName
								}
							</Typography>
						</FormGroup>
					</Grid>
				</Toolbar>
			</AppBar>
		</Box>
	);
}
export default Header;
